import endpoint from '@/api/endpoint'

export default {
  all() {
    return window.axios.get(endpoint + '/api/cases')
  },

  create(data) {
    return window.axios.post(endpoint + '/api/cases', data)
  },

  item({ id }) {
    return window.axios.get(endpoint + '/api/cases/' + id)
  },
  delete({ id }) {
    return window.axios.delete(endpoint + '/api/cases/' + id)
  },

  update(data) {
    return window.axios.post(endpoint + '/api/cases/' + data.id, data)
  },

  buildProject(caseId) {
    return window.axios.post(endpoint + '/api/cases/' + caseId + '/buildProject')
  },
}
