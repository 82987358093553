<template>
  <a
    :target="target"
    :href="href"
    @click="click"
    :class="{
      button__secondary: type === 'secondary',
      button__default: type === 'default',
      button__float: type === 'float',
      button_disabled: disabled,
      button_enabled: !disabled,
    }"
    :disabled="disabled || loading"
    class="button"
  >
    <v-progress-circular
      v-if="loading"
      class="mr-2"
      style="position: absolute; left: 1em; top: 50%; transform: translateY(-50%); z-index: 1000"
      :width="1"
      color="white"
      :size="20"
      indeterminate
    ></v-progress-circular>
    <span class="animate" :style="`margin-left: ${loading ? '2.5em' : 0}`"><slot></slot></span>
  </a>
</template>

<script>
export default {
  name: 'ButtonUI',
  props: {
    href: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'default',
      validator: (val) => ['default', 'secondary', 'text', 'float'].includes(val),
    },
    disabled: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    click(e) {
      if (this.disabled) return
      this.$emit('click', e)
      if (!this.href) {
        e.stopPropagation()
        e.preventDefault()
        return false
      }
    },
  },
}
</script>

<style scoped>
.button {
  border-radius: 10px;
  font-size: 14px;
  padding: 10px;
  text-align: center;
  display: block;
  width: 100%;
  transition: filter 0.5s;
}

.button_enabled:hover {
  -webkit-filter: brightness(90%);
  filter: brightness(90%);
}

.button__default {
  background-color: var(--accent-color, #0090ff);
  color: white;
}

.button__secondary {
  background-color: #e0e0e0;
  color: black;
}

.button_disabled {
  -webkit-filter: grayscale(90%);
  filter: grayscale(90%);
}

.button__float {
  background-color: var(--accent-color, #0090ff);
  color: white;
  position: fixed;
  width: 200px;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: rgba(20, 28, 37, 0.4) 0px 1px 30px;
  z-index: 1;
}

.button,
.button:hover,
.button:focus {
  text-decoration: none;
}

.animate {
  transition: all 0.3s ease-out;
}
</style>
