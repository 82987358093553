<script>
import cases from '@/api/cases'
import UserLinkComponent from '@/components/Profile/UserLinkComponent.vue'

export default {
  name: 'CaseItemComponent',
  components: { UserLinkComponent },
  data() {
    return {
      loading: false,
      isCaseProjectLoading: false,
      projectCase: {
        title: '',
        companyName: '',
        companyDescription: '',
        companyRepresentativeName: '',
        companyRepresentativePosition: '',
        currentState: '',
        product: '',
        additionalInformation: '',
      },
    }
  },
  computed: {
    readonly() {
      return !this.$store.getters.checkPermission('can_manage_users') && this.$store.state.user.currentUser.id != this.projectCase.user_id
    },
  },
  methods: {
    async load() {
      const item = (await cases.item({ id: this.$route.params.case_id })).data
      this.projectCase = {
        ...item,
        companyName: item.company_name,
        companyDescription: item.company_description,
        companyRepresentativeName: item.company_representative_name,
        companyRepresentativePosition: item.company_representative_position,
        currentState: item.current_state,
        additionalInformation: item.additional_information,
      }
    },
    async update() {
      this.loading = true
      await cases.update(this.projectCase)
      await this.load()
      this.loading = false
    },
    async createProject() {
      this.loading = true
      const project = (await cases.buildProject(this.projectCase.id)).data
      this.loading = false
      this.$router.push(`/projects/${project.id}`)
    },
    async deleteCase() {
      this.loading = true
      await cases.delete({ id: this.projectCase.id })
      this.$router.push('/cases')
      this.loading = false
    },
  },
  async mounted() {
    if (!this.$store.state.user.currentUser.id) return this.$router.push('/auth')
    this.isCaseProjectLoading = true
    await this.load()
    this.isCaseProjectLoading = false
  },
}
</script>

<template>
  <v-container>
    <div>
      <div
        style="background: white; padding: 20px; border-radius: 20px; "
        class="text-center text-md-left mb-4 d-flex flex-md-row flex-column align-center justify-center justify-md-start"
      >
        <div style="max-width: 100%;">
          <h2 class="max-2-lines">
            {{ projectCase.title }}
          </h2>
          <div class="d-flex flex-md-row flex-column align-center mb-4">
            <UserLinkComponent class="mr-0 mr-md-2 mt-2" :user="projectCase.user" />
            <v-chip rounded small class="mt-2" color="info" dark>
              {{ projectCase.company_name }}
            </v-chip>
          </div>
          <div class="d-flex flex-md-row flex-column align-center mb-4" style="gap: 4px">
            <v-btn @click="createProject" :loading="loading" outlined color="blue">Создать проект для этого кейса</v-btn>
            <v-btn v-if="!readonly" @click="deleteCase" :loading="loading" outlined destructive color="red">Удалить кейс</v-btn>
          </div>
        </div>
      </div>

      <v-sheet class="pa-4">
        <v-text-field
          :loading="isCaseProjectLoading"
          :readonly="readonly"
          placeholder="Безбарьерная среда для маломобильных групп населения"
          label="Название кейса"
          rounded
          style="border-radius: 10px; border-color: #efefef;"
          v-model="projectCase.title"
          outlined
        />
        <v-text-field
          :loading="isCaseProjectLoading"
          :readonly="readonly"
          label="Полное название компании, предоставившей кейс"
          placeholder="ООО «Югорский Экспертный Центр»"
          rounded
          style="border-radius: 10px; border-color: #efefef;"
          v-model="projectCase.companyName"
          outlined
        />

        <v-text-field
          :loading="isCaseProjectLoading"
          :readonly="readonly"
          label="ФИО эксперта"
          placeholder="Юлия Евгеньевна Ерохина"
          rounded
          style="border-radius: 10px; border-color: #efefef;"
          v-model="projectCase.companyRepresentativeName"
          outlined
        />

        <v-text-field
          :loading="isCaseProjectLoading"
          :readonly="readonly"
          label="Должность эксперта"
          placeholder="Генеральный директор"
          rounded
          style="border-radius: 10px; border-color: #efefef;"
          v-model="projectCase.companyRepresentativePosition"
          outlined
        />
        <v-divider />
        <v-textarea
          :readonly="readonly"
          v-model="projectCase.companyDescription"
          outlined
          hint="чем занимается, ассортимент продукции/услуг, оборот, на каких рынках работает, используемые технологии, информация о составе рабочего коллектива"
          label="Описание деятельности компании "
        />
        <v-textarea :readonly="readonly" v-model="projectCase.problem" outlined label="Описание проблемной ситуации" />
        <v-textarea :readonly="readonly" v-model="projectCase.currentState" outlined label="Как пытались решить?" />
        <v-textarea :readonly="readonly" v-model="projectCase.product" outlined label="Желаемый образ результата (продукта)" />
        <v-textarea :readonly="readonly" v-model="projectCase.additionalInformation" outlined label="Дополнительные материалы" />
        <v-btn v-if="!readonly" @click="update" :loading="loading" outlined color="blue">Сохранить</v-btn>
      </v-sheet>
    </div>
  </v-container>
</template>

<style scoped></style>
